.home_container{
  width: 100%;
  height: 200px;
  top: -55px;
  position: relative;
  height: fit-content;
}

.home{
  width: 100%;
  height: fit-content;

  display: flex;
  position: relative;
  flex-direction: column;
}

.home_cover{
  width: 75%;
}

.home_introduction{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.lang{
  width: 50%;
}

.lang:nth-child(1){
  border-right: 1px solid white;
}
@font-face {
  font-family: thamil;
  src: url(../uniAmma-001.ttf) format('truetype');
  font-display: swap;
}

.lang h1,h2,h3{
  color: aqua;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: thamil;
}

.lang h1{
  font-size: 40px;
  text-align: center;
}

.lang h2{
  font-size: 25px;
}

.lang h3{
  font-size: 25px;
  color: rgb(2, 255, 2);
}

.lang h4{
  font-size: 23px;
}

.lang h5{
  font-size: 23px;
}

.lang h6{
  font-size: 23px;
}

.home_introduction_heading{
  color: white;
}


.content h1{
  color: yellow;
  margin: 5px;
}




.flex{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}



@media screen and (max-width: 750px) {
  .home_introduction{
    flex-direction: column-reverse;
    align-items: center;
  }

  .lang:nth-child(1){
    border-right: none;
  }

  .lang{
    width: 100%;
  }

  
.lang h1{
  font-size: 25px;
}

.lang h2{
  font-size: 24px;
}

.lang h3{
  font-size: 22px;
  color: rgb(2, 255, 2);
}

.lang h4{
  font-size: 20px;
}

.lang h5{
  font-size: 20px;
}

.lang h6{
  font-size: 20px;
}
}

